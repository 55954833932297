import { StripeSubscriptionType } from "@/utils/db/types";

export const subscriptionPrices = {
  [StripeSubscriptionType.NoSubscription]: 0,
  [StripeSubscriptionType.Credits19dot99]: 20,
  [StripeSubscriptionType.Growth99]: 99,
};

export const subscriptionCredits = {
  [StripeSubscriptionType.NoSubscription]: 0,
  [StripeSubscriptionType.Credits19dot99]: 500,
  [StripeSubscriptionType.Growth99]: 2500,
};

export const subscriptionFeatures = {
  [StripeSubscriptionType.NoSubscription]: [
    "20 AI Enrichment Credits",
    "Email support",
    "Chrome extension tool",
  ],

  [StripeSubscriptionType.Credits19dot99]: [
    "500 AI Enrichment Credits",
    "Unlimited Company Search",
    "Unlimited Funding / Hiring Trigger",
    "Chrome extension tool",
    "Slack support",
    "More credits purchase as you go",
  ],

  [StripeSubscriptionType.Growth99]: [
    "Everything in Starter",
    "2500 AI Enrichment Credits",
    "Email / Phone enrichment",
    "Contact Finder",
    "10+ Extra Enrichment sources",
  ],
};

export const enterpriseFeatures = [
  "Unlimited Enrichment Credits",
  "Priority Slack support",
  "Custom Triggers",
  "Custom CRM integrations",
  "Custom enrichment sources",
  "Enterprise SLA",
];
