// Holds Stripe Releated Stuff

import { StripeSubscriptionType } from "@/utils/db/types";
import { z } from "zod";

const devStripeWebhookSecret =
  "whsec_556cc53d74b0a60b779b3d7e93f85124d9a2fe9cb6b81cb767433aa6939f9c7e";
export function stripe_webhook_secret() {
  return process.env.STRIPE_WEBHOOK_SECRET ?? devStripeWebhookSecret;
}

const devStripeSecretKey =
  "sk_test_51OP6oSAsjYmVvJ8MdKpIa384Kq9im86tkOB33bJqUeSXkHeioZ9EslOZJ8masl4x7zxcrd89Gl5TNKzhK6z4qIHn00YnMvli61";

export function stripe_secret_key() {
  return process.env.STRIPE_SK ?? devStripeSecretKey;
}

export enum StripePlanType {
  Starter = "Starter",
  Credits40 = "Credits40",
  Credits100 = "Credits100",
  Credits300 = "Credits300",
  Recurring19dot99 = "Recurring19dot99",
  Growth = "Growth",
}

export const stripePaymentMetadata = z.object({
  orgId: z.string(),
  creditsPurchase: z.number().optional().default(0),
  oneTimePlan: z.enum(["true", "false"]).optional().default("false"),
  type: z.nativeEnum(StripePlanType),
});

export type StripePaymentMetadata = z.infer<typeof stripePaymentMetadata>;

export function starterPricingId() {
  if (process.env.STAGE === "dev") {
    return "price_1PP6ilAsjYmVvJ8MRoc16Nxn";
  } else {
    return "price_1PP7uWAsjYmVvJ8MS7pUtg3n";
  }
}

export function recurring19dot99PricingId() {
  if (process.env.STAGE === "dev") {
    return "price_1PnhUBAsjYmVvJ8MA1qXhYUp";
  } else {
    return "price_1PnlJHAsjYmVvJ8MzQmAHdD8";
  }
}

export function recurringGrowthProcingId() {
  if (process.env.STAGE === "dev") {
    return "price_1PqyqLAsjYmVvJ8M3GN3h3T7";
  } else {
    return "price_1Pr4EKAsjYmVvJ8MWQifUsWm";
  }
}

export function credits40PricingId() {
  if (process.env.STAGE === "dev") {
    return "price_1Q43MNAsjYmVvJ8Mhpo4445V";
  } else {
    return "price_1Q4434AsjYmVvJ8MFyvxOJGF";
  }
}

export function credits100PricingId() {
  if (process.env.STAGE === "dev") {
    return "price_1PQCkjAsjYmVvJ8MlqTpfkSz";
  } else {
    return "price_1PQCNVAsjYmVvJ8Ml2hiXud3";
  }
}

export function credits300PricingId() {
  if (process.env.STAGE === "dev") {
    return "price_1PQClEAsjYmVvJ8MLAtLrYZe";
  } else {
    return "price_1PQCOWAsjYmVvJ8MG0aPX2I7";
  }
}

export function getPlanByPriceId(priceId: string): StripeSubscriptionType {
  if (priceId === recurring19dot99PricingId()) {
    return StripeSubscriptionType.Credits19dot99;
  }
  if (priceId === recurringGrowthProcingId()) {
    return StripeSubscriptionType.Growth99;
  }
  return StripeSubscriptionType.NoSubscription;
}

export function getPriceId(
  planType: StripeSubscriptionType
): string | undefined {
  switch (planType) {
    case StripeSubscriptionType.Credits19dot99:
      return recurring19dot99PricingId();
    case StripeSubscriptionType.Growth99:
      return recurringGrowthProcingId();
    default:
      return undefined;
  }
}
