import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-surface-dark text-text-onsurface shadow hover:bg-surface-darkHover focus:bg-surface-darkPressed focus:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)_inset,0px_2px_2px_0px_rgba(0,0,0,0.25)_inset,0px_-2px_6px_0px_rgba(255,255,255,0.12)_inset,-2px_-2px_6px_0px_rgba(255,255,255,0.08)_inset]",
        defaultNoHover: "bg-primary text-primary-foreground shadow",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-border-light bg-background-primary shadow-sm hover:bg-background-secondary hover:text-accent-foreground focus:border-border-dark",
        outlineDestructive:
          "border border-border-light text-text-error bg-background-primary shadow-sm hover:bg-background-secondary hover:text-text-errorHover focus:border-border-dark",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-brand-200",
        ghost: "hover:bg-accent hover:bg-brand-100",
        link: "text-primary underline-offset-4 hover:underline",
        brand:
          "bg-surface-brand text-white shadow-sm hover:bg-surface-brandHover pressed:bg-surface-brandPressed",
        table:
          "text-text-secondary text-xs not-italic font-[525] leading-[140%]",
        basic:
          "bg-white rounded-lg shadow-lg p-2xl transition-all hover:shadow-lg hover:bg-slate-50 active:bg-slate-100 focus:bg-white",
      },
      size: {
        default: "h-9 px-4 py-2",
        xs: "h-6 px-lg py-xs text-xs not-italic font-[525] leading-[140%] whitespace-nowrap",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-6 w-6 rounded-md my-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "sm",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

function GraphicButton({
  label,
  graphic,
}: {
  label: React.ReactNode;
  graphic: React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-3xl w-[360px] h-[360px] items-center hover:opacity-70 justify-between">
      {graphic}
      <div>{label}</div>
    </div>
  );
}

export { Button, buttonVariants, GraphicButton };
