"use client";

import { FeedbackWidgetButton } from "@/components/functional/feedback-widget-button";
import { ProfileSettings } from "@/components/functional/profile-settings";
import { ReturnIcon } from "@/components/icons/return-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { type Subscriptions, type AccountsV2 } from "@/utils/db/types";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  OpenInNewWindowIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import Link from "next/link";
import { CreditBadge } from "../credit-badge";
import { useEffect, useState } from "react";
import { CreateAccountDialog } from "@/app/(app)/dashboard/list/[slug]/account_table/components/create-account-dialog";

export function AccountHeader({
  path,
  subscription,
  alwaysHideSidebar,
  hideSideBar,
  setHideSideBar,
}: {
  path: string;
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const slug = path?.split("/").pop();

  const [account, setAccount] = useState<AccountsV2 | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (path?.startsWith("/dashboard/account")) {
      void fetch(`/api/account/details/${slug}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          await res.json().then((data: AccountsV2) => {
            setAccount(data);
          });
        }
      });
    }
  }, [path, slug]);

  return (
    <div
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-center items-center font-medium text-sm"
        }
      >
        {!alwaysHideSidebar ? (
          hideSideBar ? (
            <ChevronRightIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(false)}
            />
          ) : (
            <ChevronLeftIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(true)}
            />
          )
        ) : path ? (
          account !== null && (
            <>
              <CreateAccountDialog
                open={open}
                setOpen={setOpen}
                listId={account.listId ?? ""}
                onAccountCreated={(account) => {
                  window.location.href = `/dashboard/account/${account.id}`;
                }}
              />
              <Link href="/dashboard/lists">
                <Button
                  variant={"outline"}
                  className="flex flex-row gap-sm items-center"
                >
                  <ReturnIcon />
                  <span>Dashboard</span>
                </Button>
              </Link>
              <Link
                href={"/dashboard/list/" + account?.listId + "?account=" + slug}
                className="ml-sm"
              >
                <Button
                  variant={"outline"}
                  className="flex flex-row gap-sm items-center"
                  disabled={account === null}
                >
                  <OpenInNewWindowIcon />
                  <span>List</span>
                </Button>
              </Link>
              <Button
                variant={"brand"}
                className="flex flex-row gap-sm items-center ml-sm"
                onClick={() => setOpen(true)}
              >
                <PlusIcon />
                <span>Create New Account</span>
              </Button>
            </>
          )
        ) : (
          <div className="pl-2xl font-medium text-base">
            <span className="text-primary text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Lava
            </span>
            <span className="text-surface-brand text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Reach
            </span>
          </div>
        )}
        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}
