"use client";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { LoadingIcon } from "@/components/ui/loading-icon";
import { Separator } from "@/components/ui/separator";
import { AlertDialogTrigger } from "@radix-ui/react-alert-dialog";
import React, { useState } from "react";

export function DeleteActionAlertDialog({
  title,
  description,
  actionText = "Delete",
  open,
  setOpen,
  onDelete,
  trigger,
  disabled,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  actionText?: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => Promise<void>;
  trigger?: React.ReactNode | undefined;
  disabled?: boolean;
}) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (deleting) return;
    setDeleting(true);

    void onDelete().then(() => {
      setOpen(false);
      setDeleting(false);
    });
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {trigger && <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>}
      <AlertDialogContent className="pt-xl pb-0 px-0">
        <AlertDialogHeader className="px-2xl">
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <Separator />
        <AlertDialogFooter className="px-2xl pb-lg">
          <AlertDialogCancel asChild>
            <Button size="sm" variant="outline">
              Cancel
            </Button>
          </AlertDialogCancel>
          <Button
            size="sm"
            variant="outlineDestructive"
            onClick={handleDelete}
            disabled={deleting || disabled}
          >
            {deleting ? <LoadingIcon /> : actionText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
