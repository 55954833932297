import {
  Editor,
  Range,
  Transforms,
  type BasePoint,
  type Descendant,
} from "slate";
import { type ReactEditor } from "slate-react";
export function nodeEmpty(node: Descendant | undefined) {
  if (!node) return true;

  const nodeChildren = node as { children: { text: string }[] };

  if (!nodeChildren.children) return true;

  return (
    nodeChildren.children.filter((n) => n.text && n.text.trim().length > 0)
      .length === 0
  );
}

// function splitJsonStrings(input: string) {
//   const results = [];
//   let depth = 0; // This keeps track of the depth of nested objects
//   let currentJson = "";
//   let inSingleBrace = false; // This flags if the last brace was a single opening brace

//   for (let i = 0; i < input.length; i++) {
//     const char = input[i];

//     // Start capturing if we encounter a '{' and it is not preceded immediately by another '{'
//     if (char === "{") {
//       if (i > 0 && input[i - 1] === "{") {
//         inSingleBrace = false; // It's a double '{{', so ignore this start
//       } else {
//         inSingleBrace = true; // It's a single '{', consider this a potential start
//       }
//     }

//     // If it's a valid starting '{', increment depth
//     if (inSingleBrace && char === "{") {
//       if (depth === 0) {
//         results.push(currentJson);
//         currentJson = ""; // Start a new JSON string capture
//       }
//       depth++;
//     }

//     // Capture the character if we are within a JSON object
//     currentJson += char;

//     // When encountering '}', decrement the depth
//     if (char === "}") {
//       if (i < input.length - 1 && input[i + 1] === "}") {
//         //ignore
//       } else {
//         depth--;
//         if (depth === 0) {
//           // If we are back to zero, we've closed a JSON object
//           results.push(currentJson);
//           currentJson = "";
//           inSingleBrace = false; // Reset this for the next potential JSON object
//         }
//       }
//     }
//   }

//   results.push(currentJson);

//   return results;
// }

export function slatePreviewString(slateString: string) {
  return "(Preview): " + cleanTags(slateString);
}

// For deseralizeingToSlate
export function slateStringToHtmlElement(slateString: string) {
  return new DOMParser().parseFromString(slateString, "text/html").body;
}

export function SlatePreviewHtml({ slateString }: { slateString: string }) {
  const paragraphs = slateString.split("\n\n");
  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <div key={index}>
          {paragraph.split("\n").map((line) => (
            <div dangerouslySetInnerHTML={{ __html: line }} />
          ))}
          {index < paragraphs.length - 1 && <br key={index} />}
        </div>
      ))}
    </div>
  );
}

export function cleanTags(slateString: string) {
  return slateString.replace(/<[^>]*>/g, "");
}

export function cleanVTags(slateString: string) {
  return slateString
    .replaceAll("<v>", "")
    .replaceAll("%3Cv%3E", "")
    .replaceAll("</v>", "")
    .replaceAll("%3C/v%3E", "")
    .replaceAll("&lt;v&gt;", "")
    .replaceAll("&lt;/v&gt;", "");
}

// pads string with <ai> and <v> tags
export function stringToHtml(slateString: string) {
  return slateString
    .replace(/\[\[/g, "<ai>[[")
    .replace(/\]\]/g, "]]</ai>")
    .replace(/\{\{/g, "<v>{{")
    .replace(/\}\}/g, "}}</v>");
}

// export function mergeSlate(slateString: string) {
//   let outString = "";
//   const split = splitJsonStrings(slateString);

//   for (const line of split) {
//     // console.log("line:", line);
//     if (isValidJson(line)) {
//       const element = JSON.parse(line) as CustomElement;
//       // console.log("element:", element);
//       if (element.type === "text") {
//         if (element.children != null) {
//           outString += element.children
//             .map((c) => mergeSlate(JSON.stringify(c)))
//             .join("");
//         } else {
//           outString += `${(element as { text?: string }).text ?? ""}\n`;
//         }
//       } else if (element.type === "image") {
//         const imageElement = element as { url: string };
//         outString += `![${imageElement.url}](${imageElement.url})\n`;
//       } else if (element.type === "link") {
//         const linkElement = element as { url: string; children: TextElement[] };
//         outString += `[${linkElement.children
//           .map((c) => mergeSlate(JSON.stringify(c)))
//           .join("")}](${element.url})`;
//       } else if (element.type === "aiPrompt") {
//         const aiPromptElement = element as { children: TextElement[] };
//         outString += `[[${aiPromptElement.children
//           .map((c) => mergeSlate(JSON.stringify(c)))
//           .join("")}]]`;
//       } else {
//         // console.log("not text element:", element);
//         const customTextElement = element as {
//           text?: string;
//           bold?: boolean;
//           italic?: boolean;
//           underline?: boolean;
//           strikeThrough?: boolean;
//         };

//         if (customTextElement.text) {
//           if (customTextElement.text.length === 0) {
//             outString += "\n";
//           } else {
//             const bold = customTextElement.bold ? "<b>" : "";
//             const boldFinish = customTextElement.bold ? "</b>" : "";
//             const italic = customTextElement.italic ? "<i>" : "";
//             const italicFinish = customTextElement.italic ? "</i>" : "";
//             const underline = customTextElement.underline ? "<u>" : "";
//             const underlineFinish = customTextElement.underline ? "</u>" : "";
//             const strikeThrough = customTextElement.strikeThrough ? "<s>" : "";
//             const strikeThroughFinish = customTextElement.strikeThrough
//               ? "</s>"
//               : "";

//             outString += `${bold}${italic}${underline}${strikeThrough}`;
//             outString += `${customTextElement.text}`;
//             outString += `${strikeThroughFinish}${underlineFinish}${italicFinish}${boldFinish}`;
//           }
//         } else {
//           // outString += `${line}\n`;
//         }
//       }
//     } else {
//       // console.log("invalid line:", line);
//       outString += `${line}`;
//     }
//   }

//   // console.log("mergeSlate:", outString);
//   return outString;
// }

// define word character as all EN letters, numbers, and dash
// change this regexp if you want other characters to be considered a part of a word
const wordRegexp = /[0-9a-zA-Z-_\{\}\[\]]/;

const getLeftChar = (editor: ReactEditor, point: BasePoint) => {
  const end = Range.end(editor.selection!);

  const char = Editor.string(editor, {
    anchor: {
      path: end.path,
      offset: point.offset - 1,
    },
    focus: {
      path: end.path,
      offset: point.offset,
    },
  });

  return char;
};

const getRightChar = (editor: ReactEditor, point: BasePoint) => {
  const end = Range.end(editor.selection!);
  return Editor.string(editor, {
    anchor: {
      path: end.path,
      offset: point.offset,
    },
    focus: {
      path: end.path,
      offset: point.offset + 1,
    },
  });
};

export const getWorldFromSelection = ({
  editor,
  selection,
  searchRight,
  searchLeft,
}: {
  editor: ReactEditor;
  selection: Range;
  searchRight: boolean;
  searchLeft: boolean;
}) => {
  const end = Range.end(selection); // end is a Point
  let currentWord = "";
  // this is just a deep copy
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentPosition = JSON.parse(JSON.stringify(end)) as BasePoint;
  let startOffset = end.offset;
  let endOffset = end.offset;

  // go left from cursor until it finds the non-word character
  while (
    searchLeft &&
    currentPosition.offset >= 0 &&
    getLeftChar(editor, currentPosition).match(wordRegexp)
  ) {
    currentWord = getLeftChar(editor, currentPosition) + currentWord;
    startOffset = currentPosition.offset - 1;
    currentPosition.offset--;
  }

  // go right from cursor until it finds the non-word character
  currentPosition.offset = end.offset;
  while (
    searchRight &&
    currentWord.length &&
    getRightChar(editor, currentPosition).match(wordRegexp)
  ) {
    currentWord += getRightChar(editor, currentPosition);
    endOffset = currentPosition.offset + 1;
    currentPosition.offset++;
  }

  const currentRange: Range = {
    anchor: {
      path: end.path,
      offset: startOffset,
    },
    focus: {
      path: end.path,
      offset: endOffset,
    },
  };

  return {
    currentWord,
    currentRange,
  };
};

export const getWordBeforeAfter = ({ editor }: { editor: ReactEditor }) => {
  const { selection } = editor; // selection is Range type
  if (!selection) {
    console.log("getWordBeforeAfter no selection");
    return {
      beforeWord: "",
      afterWord: "",
    };
  }

  const end = Range.end(selection); // end is a Point
  let currentWord = "";
  // this is just a deep copy
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentPosition = JSON.parse(JSON.stringify(end)) as BasePoint;

  // go left from cursor until it finds the non-word character
  while (
    currentPosition.offset >= 0 &&
    getLeftChar(editor, currentPosition).match(wordRegexp)
  ) {
    // console.log("getLeftChar:", currentWord);
    currentWord = getLeftChar(editor, currentPosition) + currentWord;
    currentPosition.offset--;
  }

  const beforeWord = currentWord;
  currentWord = "";

  // go right from cursor until it finds the non-word character
  currentPosition.offset = end.offset;

  while (getRightChar(editor, currentPosition).match(wordRegexp)) {
    // console.log("getRightChar:", currentWord);
    currentWord += getRightChar(editor, currentPosition);
    currentPosition.offset++;
  }

  return {
    beforeWord: beforeWord,
    afterWord: currentWord,
  };
};

export const getCurrentWord = (editor: ReactEditor) => {
  const { selection } = editor; // selection is Range type

  if (selection) {
    return getWorldFromSelection({
      editor,
      selection,
      searchRight: true,
      searchLeft: true,
    });
  }

  return {
    currentWord: undefined,
    currentRange: undefined,
  };
};

export const getBeforeWord = (editor: ReactEditor) => {
  const { selection } = editor; // selection is Range type

  if (selection) {
    return getWorldFromSelection({
      editor,
      selection,
      searchRight: false,
      searchLeft: true,
    });
  }

  return {
    currentWord: undefined,
    currentRange: undefined,
  };
};

export function tryToCompleteVariable(editor: ReactEditor): boolean {
  const { currentWord, currentRange } = getCurrentWord(editor);

  if (
    currentWord &&
    currentWord.length > 2 &&
    currentWord.startsWith("{") &&
    currentWord[1] === "{"
  ) {
    Transforms.select(editor, currentRange);
    Transforms.insertNodes(editor, [
      {
        text: `${currentWord}}}`,
        variable: true,
      },
      { text: " " },
    ]);
    return true;
  } else {
    return false;
  }
}

export function tryToInsertAfterVariable(
  editor: ReactEditor,
  key: string
): boolean {
  const selection = editor.selection!;
  if (selection) {
    const [start] = Range.edges(selection);
    //check previous word:
    const before = Editor.before(editor, start, { unit: "character" });
    const before2 = before && Editor.before(editor, start, { unit: "word" });
    const wordBefore =
      before2 && Editor.string(editor, { anchor: before2, focus: start });

    if (
      wordBefore &&
      wordBefore.length > 1 &&
      wordBefore.endsWith("}") &&
      wordBefore[wordBefore.length - 2] === "}"
    ) {
      Transforms.insertNodes(editor, { text: key });
      return true;
    }
  } else {
    console.log("no selection");
  }

  return false;
}

export function tryToInsertAfterPrompt(
  editor: ReactEditor,
  key: string
): boolean {
  const selection = editor.selection!;
  if (selection) {
    const [start] = Range.edges(selection);
    //check previous word:
    const before = Editor.before(editor, start, { unit: "character" });
    const before2 = before && Editor.before(editor, start, { unit: "word" });
    const wordBefore =
      before2 && Editor.string(editor, { anchor: before2, focus: start });

    if (
      wordBefore &&
      wordBefore.length > 1 &&
      wordBefore.endsWith("]") &&
      wordBefore[wordBefore.length - 2] === "]"
    ) {
      // Transforms.insertNodes(editor, [ { text: key }]);
      Transforms.insertNodes(editor, {
        type: "text",
        children: [{ text: key }],
      });
      return true;
    }
  } else {
    console.log("no selection");
  }

  return false;
}
