"use client";

import { SidebarContext } from "@/hooks/sidebar-context";
import { SubscriptionContext } from "@/hooks/subscription-context";
import { cn } from "@/lib/utils";
import { type Subscriptions } from "@/utils/db/types";
import { usePathname, useRouter } from "next/navigation";
import { type PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Header } from "./Header";
import { ListBuilderHeader } from "./headers/list-header";
import { Sidebar } from "./Sidebar";
import { SettingsHeader } from "./headers/settings-header";
import { AccountHeader } from "./headers/account-header";

// TODO: do we need to check for subscription here?
export function PaymentChecker({
  subscription,
}: {
  subscription: Subscriptions | undefined | null;
}) {
  const pathName = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (!subscription) {
      if (pathName !== "/dashboard/no-org") router.push("/dashboard/no-org");
      return;
    }
  }, [subscription, pathName]);

  return null;
}

export function shouldHideSidebar(path: string) {
  if (
    path.startsWith("/dashboard/list/") ||
    path.startsWith("/dashboard/sequences/") ||
    path.startsWith("/dashboard/list-builder") ||
    path.startsWith("/dashboard/list-stats")
  ) {
    return true;
  }
  return false;
}

export function isListPath(path: string) {
  if (
    path.startsWith("/dashboard/list/") ||
    path.startsWith("/dashboard/list-stats")
  ) {
    return true;
  }

  return false;
}

export function isListBuilderPath(path: string) {
  return path.startsWith("/dashboard/list-builder");
}

export function isSettingsPath(path: string) {
  return path.startsWith("/dashboard/settings");
}

export function isAccountPath(path: string) {
  return path.startsWith("/dashboard/account");
}

export function DashboardClientLayout({
  subscription,
  children,
}: PropsWithChildren<{
  subscription: Subscriptions;
}>) {
  let hideSideBarV = false;
  // subscription = {
  //   // DEBUG
  //   ...subscription,
  //   simpleUI: true,
  // };

  const [subscriptionContextValue, setSubscription] =
    useState<Subscriptions>(subscription);

  const path = usePathname();

  if (path) {
    if (shouldHideSidebar(path)) {
      hideSideBarV = true;
    }
  }

  useEffect(() => {
    if (path) {
      if (shouldHideSidebar(path)) {
        setHideSideBar(true);
      }
    }
  }, [path]);

  const [hideSideBar, setHideSideBar] = useState(hideSideBarV);
  const sideBarLength = useMemo(
    () => (hideSideBar || subscription.simpleUI ? 0 : 180),
    [hideSideBar, subscription.simpleUI]
  );

  const customHeaderLayout = path && isListPath(path);
  const listBuilderHeader = path && isListBuilderPath(path);
  const settingsHeader = path && isSettingsPath(path);
  const accountHeader = path && isAccountPath(path);

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      window.location.reload();
    };

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onpopstate = () => {};
    };
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription: subscriptionContextValue,
        consumeCredits: (credits: number) => {
          setSubscription((prev) => ({
            ...prev,
            creditsUsed: prev.creditsUsed + credits,
          }));
        },
      }}
    >
      <PaymentChecker subscription={subscription} />

      {customHeaderLayout ? (
        <>{children}</>
      ) : (
        <SidebarContext.Provider value={sideBarLength}>
          <div className="hidden lg:flex flex-row w-full h-full">
            {!hideSideBar && !subscription.simpleUI && (
              <Sidebar subscription={subscription} />
            )}
            <div
              className={cn("flex flex-col w-full")}
              style={{ marginLeft: hideSideBar ? 0 : `${sideBarLength}px` }}
            >
              {listBuilderHeader ? (
                <ListBuilderHeader
                  subscription={subscription}
                  alwaysHideSidebar={subscription.simpleUI}
                  hideSideBar={hideSideBar}
                  setHideSideBar={setHideSideBar}
                />
              ) : settingsHeader ? (
                <SettingsHeader
                  path={path}
                  subscription={subscription}
                  alwaysHideSidebar={subscription.simpleUI}
                  hideSideBar={hideSideBar}
                  setHideSideBar={setHideSideBar}
                />
              ) : accountHeader ? (
                <AccountHeader
                  path={path}
                  subscription={subscription}
                  alwaysHideSidebar={subscription.simpleUI}
                  hideSideBar={hideSideBar}
                  setHideSideBar={setHideSideBar}
                />
              ) : (
                <Header
                  subscription={subscription}
                  alwaysHideSidebar={subscription.simpleUI}
                  hideSideBar={hideSideBar}
                  setHideSideBar={setHideSideBar}
                />
              )}

              {children}
            </div>
          </div>
        </SidebarContext.Provider>
      )}
    </SubscriptionContext.Provider>
  );
}
