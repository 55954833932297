export function ReturnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 5.3335H11C12.6569 5.3335 14 6.67664 14 8.3335C14 9.99035 12.6569 11.3335 11 11.3335H2M2 5.3335L4 3.3335M2 5.3335L4 7.3335"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
