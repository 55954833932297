import { Button } from "../ui/button";

export default function PenSquareIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="pen-square">
        <path
          id="Icon"
          d="M5.95829 2.66669H3.89996C3.29324 2.66669 2.98987 2.66669 2.75814 2.78476C2.55429 2.88862 2.38857 3.05435 2.2847 3.2582C2.16663 3.48993 2.16663 3.7933 2.16663 4.40002V9.60002C2.16663 10.2067 2.16663 10.5101 2.2847 10.7418C2.38857 10.9457 2.55429 11.1114 2.75814 11.2153C2.98987 11.3334 3.29324 11.3334 3.89996 11.3334H9.09996C9.70668 11.3334 10.01 11.3334 10.2418 11.2153C10.4456 11.1114 10.6114 10.9457 10.7152 10.7418C10.8333 10.5101 10.8333 10.2067 10.8333 9.60002V7.27085M8.39579 3.47919L9.92786 5.01125M5.82978 6.0452L9.43091 2.44407C9.85398 2.021 10.5399 2.021 10.963 2.44407C11.386 2.86714 11.386 3.55307 10.963 3.97614L7.24607 7.69304C6.83352 8.10559 6.62724 8.31187 6.39228 8.47592C6.18372 8.62153 5.9588 8.74215 5.72211 8.83531C5.45546 8.94027 5.16951 8.99796 4.59759 9.11336L4.33329 9.16669L4.359 8.98677C4.44995 8.35011 4.49542 8.03178 4.59887 7.73458C4.69066 7.47086 4.81605 7.22007 4.97195 6.98841C5.14764 6.72734 5.37502 6.49996 5.82978 6.0452Z"
          stroke="#64748B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function PenSquareIconTableRowButton() {
  return (
    <Button
      size={"icon"}
      variant={"ghost"}
      className="text-text-secondary bg-white my-0"
    >
      <PenSquareIcon />
    </Button>
  );
}
