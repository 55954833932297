import { cn } from "@/lib/utils";
import Image from "next/image";

export function LoadingPlaceholder({
  text,
  className,
}: {
  text?: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex h-full w-full items-center justify-items-center justify-center",
        className
      )}
    >
      <div className="flex items-center gap-lg">
        <Image
          unoptimized
          src={"/lavareach_logo_loading.gif"}
          width={38}
          height={38}
          priority
          alt="LavaReach Logo"
        />
        <span className="text-muted-foreground">{text ?? "Loading..."}</span>
      </div>
    </div>
  );
}
