import {
  type CreateAccountRequest,
  type CreateAccountResponse,
} from "@/app/api/account/create/route";
import { Button } from "@/components/ui/button";
import {
  CompactDialogBody,
  CompactDialogContent,
  CompactDialogFooter,
  CompactDialogHeader,
  Dialog,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoadingIcon } from "@/components/ui/loading-icon";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { cleanWebsite } from "@/utils/email_utils";
import { type AccountsV2 } from "@/utils/db/types";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

export interface CreateAccountDialogProps {
  listId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onAccountCreated: (account: AccountsV2) => void;
}

export interface AccountCreateType {
  accountId: string;
  name: string;
  website: string;
}

const createAccountSchema = z.object({
  website: z.string().min(1),
});

type CreateAccountValues = z.infer<typeof createAccountSchema>;

export function CreateAccountDialog({
  open,
  setOpen,
  listId,
  onAccountCreated,
}: CreateAccountDialogProps) {
  const [creating, setCreating] = useState(false);

  const form = useForm<CreateAccountValues>({
    resolver: zodResolver(createAccountSchema),
    defaultValues: {
      website: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open]);

  function createAccount(values: CreateAccountValues) {
    if (creating) return;
    setCreating(true);

    const cleanedWebsite = values.website
      ? cleanWebsite(values.website)
      : values.website;
    void fetch("/api/account/create", {
      method: "POST",
      body: JSON.stringify({
        listId: listId,
        accountWebsite: cleanedWebsite,
      } as CreateAccountRequest),
    }).then((res) => {
      if (res.ok) {
        setCreating(false);
        toast({ title: "Account Created" });
        void res.json().then((data: CreateAccountResponse) => {
          onAccountCreated(data.accountCreated);
        });
      } else {
        toast({
          title: "Failed to create account",
          description: "Please try again later",
          variant: "destructive",
        });
      }
      setOpen(false);
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <FormProvider {...form}>
        <CompactDialogContent>
          <CompactDialogHeader>
            <DialogTitle>Create New Account</DialogTitle>
            <Label>This action costs 0 credit(s)</Label>
          </CompactDialogHeader>
          <Separator />
          <form
            /* We have to keep this async here to process the form properly */
            /* eslint-disable-next-line  @typescript-eslint/no-misused-promises */
            onSubmit={form.handleSubmit(createAccount)}
          >
            <CompactDialogBody>
              <FormField
                control={form.control}
                name="website"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-2 items-center gap-4 space-y-0">
                    <FormLabel className="text-left">Account Website</FormLabel>
                    <FormControl>
                      <Input
                        autoComplete="off"
                        placeholder="https://lavareach.com"
                        className="col-span-1"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-4" />
                  </FormItem>
                )}
              />
            </CompactDialogBody>
            <Separator />
            <CompactDialogFooter>
              <Button type="submit" disabled={creating}>
                {creating ? <LoadingIcon /> : "Create"}
              </Button>
            </CompactDialogFooter>
          </form>
        </CompactDialogContent>
      </FormProvider>
    </Dialog>
  );
}
